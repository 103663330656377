export const TribeModule = {
    state: {
        tribe: {}
    },
    getters: {
        getLogo: (state) => state.tribe.logo
    },
    mutations: {
        SET_TRIBE: (state, tribe) => state.tribe = tribe
    },
    actions: {
        setTribe: ({commit}, tribe) => commit('SET_TRIBE', tribe)
    }
}
