import dayjs from "dayjs";
import {i18n} from "@/plugins/i18n.plugin";

require('dayjs/locale/nl-be')
require('dayjs/locale/de')
require('dayjs/locale/fr')
require('dayjs/locale/pl')


export const ConfigModule = {
    state: {
        config: {
            language: 'nl',
            time: 1624448672
        }
    },
    getters: {
        getLanguage: (state) => {
            return state.config.language === 'nl' ? 'nl-be' : state.config.language
        },
        getTime: (state) => {
            return state.config.time
        },
    },
    mutations: {
        SET_CONFIG: (state, config) => state.config = config
    },
    actions: {
        setConfig: ({commit, getters}, config) =>{
            commit('SET_CONFIG', config)
            dayjs.locale(getters.getLanguage)
            i18n.locale = getters.getLanguage
        }
    }
}
