import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
import nl from "../locales/nl-be.json";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import de from "../locales/de.json";

export const i18n = new VueI18n({
  locale: "nl-be",
  fallbackLocale: "en",
  messages: {
    "nl-be": nl,
    en,
    fr,
    de,
  },
});
