import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import * as LocalstorageService from '@/services/LocalstorageService'
import * as APIService from '@/services/APIService'

import {store} from '@/store/store'

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/views/Main"),
    beforeEnter: (to, from, next) => {
      const key = LocalstorageService.getKey();
      if (key === null) {
        next({ name: "launch" });
      } else {
        store.dispatch("setKey", key);
        next();
      }
    },
  },
  {
    path: "/launch",
    name: "launch",
    component: () => import("@/views/Launch"),
    beforeEnter: (to, from, next) => {
      const key = LocalstorageService.getKey();
      if (key !== null) {
        store.dispatch("setKey", key);
      }
      next();
    },
  },
  {
    path: "/remote/:id",
    name: "remote",
    component: () => import("@/views/Remote"),
  },
  {
    path: "/:key",
    name: "key",
    beforeEnter: (to, from, next) => {
      APIService.updateData(to.params.key)
        .then(() => {
          LocalstorageService.setKey(to.params.key);
          store.dispatch("setKey", to.params.key);
          next({ name: "main" });
        })
        .catch(() => {
          next({ name: "launch" });
        });
    },
  },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
