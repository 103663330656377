function setKey(key) {
    localStorage.setItem('key', key)
}

function getKey() {
    return localStorage.getItem('key')
}

function clearKey() {
    localStorage.removeItem('key')
}

export {
    setKey,
    getKey,
    clearKey
}
