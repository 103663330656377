import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import {ColorsModule} from "@/store/_colors.module";
import {TribeModule} from "@/store/_tribe.module";
import {ConfigModule} from "@/store/_config.module";

export const store = new Vuex.Store({
    state: {
        DISABLE_TIMERS: false,
        key: null,
        is_loading: true,
        play: false,
    },
    getters: {
        getKey: (state) => state.key,
        hasKey: (state) => state.key !== null,
        getPlay: (state) => state.play
    },
    mutations: {
        SET_KEY: (state, key) => state.key = key,
        SET_IS_LOADING: (state, value) => state.is_loading = value,
        SET_PLAY: (state, value) => state.play = value
    },
    actions: {
        setPlay: ({commit}, boolean) => commit('SET_PLAY', boolean),
        setKey: ({commit}, key) => commit('SET_KEY', key)
    },
    modules: {
        ColorsModule,
        TribeModule,
        ConfigModule
    }
})
