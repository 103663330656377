export function hexToRGB(hex) {
    if (typeof hex !== "string") return ''

    const red = parseInt(hex.substr(1, 2), 16)
    const green = parseInt(hex.substr(3, 2), 16)
    const blue = parseInt(hex.substr(5, 2), 16)

    return {
        red: parseInt(red, 10),
        green: parseInt(green, 10),
        blue: parseInt(blue, 10)
    }
}


export function hexToNumberlist(hex) {
    const rgb = hexToRGB(hex)
    const numberList = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0]
    numberList[0] = (rgb.red / 255).toFixed(2)
    numberList[6] = (rgb.green / 255).toFixed(2);
    numberList[12] = (rgb.blue / 255).toFixed(2);
    return numberList
}

export const EVERY_SECOND = 1000;
export const EVERY_MINUTE = EVERY_SECOND * 60;
export const EVERY_HOUR = EVERY_MINUTE * 60;
export const EVERY_DAY = EVERY_HOUR * 24;
