window.Peer = require("peerjs");

import Vue from "vue";
import App from "./App.vue";
import { i18n } from "./plugins/i18n.plugin";
import { store } from "@/store/store";
import "@/assets/style/style.scss";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

if (window.origin.includes("https://")) {
  Sentry.init({
    dsn: "https://d0fe634a0d20417dad81f7a9d51e8052@sentry.appwi.se/196",
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    environment: process.env.NODE_ENV,
  });
}

import router from "@/router/router";

import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

Vue.config.productionTip = false;
Vue.config.performance = true;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
