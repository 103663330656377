<template>
  <div id="app" class="overflow-hidden bg-background">
    <div class="network-error" v-show="!online">Geen internetverbinding</div>

    <router-view></router-view>


  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
      return {
        online: true
      }
  },
  mounted() {
    this.updateNetworkState(window.navigator.onLine);

    window.addEventListener('online', () => {this.updateNetworkState(true) });

    window.addEventListener('offline', () => {this.updateNetworkState(false)});
  },
  methods: {
    updateNetworkState(isOnline) {
      if( !this.online && isOnline){
        window.location.reload()
      }

      this.online = ( isOnline ? true : false )
    },
  }
}
</script>

<style lang="scss">
  .network-error {
    z-index: 99;
    @apply bg-red-600 rounded p-4 text-white w-1/3 text-center top-5 right-5 absolute text-lg
  }
</style>
