import * as Helpers from "@/helpers/helpers"

export const ColorsModule = {
    state: {
        hexColor: '#58B7D5'
    },
    getters: {
        getColorMatrix: (state) => Helpers.hexToNumberlist(state.hexColor),
        getHexColor: (state) => state.hexColor,
        getRGBColors: (state) => Helpers.hexToRGB(state.hexColor)
    },
    mutations: {
        SET_HEX_COLOR: (state, hexString) => state.hexColor = hexString
    },
    actions: {
        setHexColor: ({commit}, hexString) => commit('SET_HEX_COLOR', hexString)
    }
}
